import React from 'react'
import { usePlaceholder } from 'interfaces-core'
import { Link } from 'react-router-dom'


import style from '../../../all.module.css'

const RegularButton  = (props) => {
    return (

        <button className={style.RegularButton}>Open</button>

    )
}

    export default RegularButton
import React from 'react'


import style from './covenants-sub-sections.module.css'
import FilterMenu from '../../../../components/Global/FilterMenu/index.js'
import RoutineInfo from '../../../../components/Covenants/RoutineInfo/index.js'



const RoutineView = (props) => {
  return (
  <>
    <RoutineInfo></RoutineInfo>
  </>
  )
}


export default RoutineView

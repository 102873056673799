import React from 'react'
import { usePlaceholder } from 'interfaces-core'
import { Link } from 'react-router-dom'


import style from '../../../all.module.css'

const RegularMiniButton  = (props) => {
    return (

        <button className={style.RegularMiniButton}>Open</button>

    )
}

    export default RegularMiniButton
import React from 'react'


import style from './covenants-sections.module.css'
import Trade from '../../../../components/Global/Trade/index.js'




const FarmMain = (props) => {
  return (
  <div className={style.CovenantsMainBox0}>
    <Trade></Trade>
  </div>
  )
}


export default FarmMain

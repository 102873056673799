import React from 'react'


import style from './covenants-sub-sections.module.css'
import FilterMenu from '../../../../components/Global/FilterMenu/index.js'
import FarmInfo from '../../../../components/Covenants/FarmInfo/index.js'



const RoutineView = (props) => {
  return (
  <>
    <FarmInfo></FarmInfo>
  </>
  )
}


export default RoutineView
